<template>
  <div id="app" class="url" style="padding: 15px">
    <el-row>
      <el-col :span="24" style="margin-bottom: 10px">
        <el-page-header @back="goBack" content="公司管理系统"> </el-page-header>
      </el-col>
    </el-row>

    <!-- 公司结构 -->
    <div>
      <el-input
        placeholder="输入关键字进行搜索..."
        prefix-icon="el-icon-search"
        v-model="filterText"
        style="margin: 0 10px 5px 0; width: 300px"
      >
      </el-input>
      <el-button type="success" @click="dialogVisible1 = true"
        >添加新公司</el-button
      >

      <el-dialog
        title="添加新公司"
        :visible.sync="dialogVisible1"
        width="30%"
      >
        <el-input v-model="form.info" placeholder="请输入公司名称"></el-input>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible1 = false">取 消</el-button>
          <el-button type="primary" @click="AddNewCompany">确 定</el-button>
        </span>
      </el-dialog>

      <!-- 添加子公司弹框 -->
      <el-dialog
        title="添加公司"
        :visible.sync="dialogVisible2"
        width="30%"
        :close-on-click-modal="false"
      >
      <div>
        <table>
          <tr style="margin-bottom:10px">
            <td>
              <el-tag style="font-size:16px">上级公司</el-tag>
            </td>
            <td style="font-size:16px;padding-left:5px;font-family:'宋体';font-weight:600">{{perName}}</td>
          </tr>
          <tr>
            <td>
              <el-tag style="margin:0 15px 0 0;font-size:16px">公司名称</el-tag></el-tag>
            </td>
            <td style="width:100%">
              <el-input 
                v-model="company.name" 
                placeholder="请输入公司名称"
              >
                </el-input>
            </td>
          </tr>
        </table>
      </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible2 = false">取 消</el-button>
          <el-button type="primary" @click="doAddCompany">确 定</el-button>
        </span>
      </el-dialog>

      <!-- 编辑公司名称 -->
      <el-dialog
        title="修改公司名称"
        :visible.sync="dialogVisible3"
        width="30%"
      >
        <el-input v-model="company1.name1"></el-input>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible3 = false">取 消</el-button>
          <el-button type="primary" @click="doEditCompany">确 定</el-button>
        </span>
      </el-dialog>

      <el-tree
        :data="allCompanyList"
        :props="defaultProps"
        default-expand-all
        :filter-node-method="filterNode"
        :expand-on-click-node="false"
        ref="tree"
        style="height: 400px;overflow-y:scroll"
      >
        <span class="custom-tree-node" slot-scope="{ node, data }">
          <span>{{ node.label }}</span>
          <span>
            <el-button
              type="primary"
              plain
              size="mini"
              class="add"
              @click="() => addCompany(data)"
            >
              添加
            </el-button>
            <el-button
              type="success"
              plain
              size="mini"
              class="add"
              @click="() => editCompany(data)"
            >
              编辑
            </el-button>
            <el-button
              type="danger"
              plain
              size="mini"
              class="del"
              @click="() => delCompany(data.id)"
            >
              删除
            </el-button>
          </span>
        </span>
      </el-tree>

      <el-dialog title="添加公司" :visible.sync="dialogVisible" width="30%">
        <div></div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="dialogVisible = false"
            >确 定</el-button
          >
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
// 公司系统
import companySystem from "@/api/managementApi/CompanyInfo";

export default {
  data() {
    return {
      dialogVisible: false,
      dialogVisible1: false,
      dialogVisible2: false,
      dialogVisible3: false,

      allCompanyList: [], // 公司集合
      form: {
        info: "",
      },
      company: {
        name: "",
        parentId: -1,
      },
      company1: {
        name1: "",
        parentId1: -1,
      },
      perName: "",
      filterText: "",

      defaultProps: {
        children: "smallCompanyList", // 子公司节点
        label: "name", // 公司名
      },
    };
  },

  created() {
    this.getAllCompanyList();
  },

  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
  },

  methods: {
    // 返回上一级
    goBack() {
      this.$router.push("/Select");
    },

    // 查询所有公司结构
    getAllCompanyList() {
      companySystem.getAllCompanyStructure().then((res) => {
        // console.log(res);
        this.allCompanyList = res.data.data.list;
      });
    },

    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },

    // 添加新的顶级公司
    AddNewCompany() {
      // 处理添加过程
      if (this.form.info != "") {
        companySystem.addHighestCompanyInfo(this.form.info).then(() => {
          // console.log(res);
          this.dialogVisible1 = false;
          this.form.info = "";
          this.getAllCompanyList();
        });
      } else {
        this.$message.error("系统名称不能为空！");
      }
    },

    // 添加子公司
    addCompany(data) {
      this.company.parentId = data.id;
      this.perName = data.name;
      this.dialogVisible2 = true;
    },
    doAddCompany() {
      // companySystem.addSmallCompanyInfo
      if (this.company.name != "") {
        companySystem
          .addSmallCompanyInfo(this.company.parentId, this.company.name)
          .then(() => {
            // console.log(res);
            this.dialogVisible2 = false;
            this.company.name = "";
            this.getAllCompanyList();
          });
      } else {
        this.$message.error("系统名称不能为空！");
      }
    },

    // 删除公司
    delCompany(id) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        //点击确定，指定then方法
        companySystem.deleteCompanyInfo(id).then((res) => {
          //提示信息
          this.$message({
            type: res.data.success ? "success" : "warning",
            message: res.data.message,
          });
          //回到页面并刷新
          this.getAllCompanyList();
        });
      });
    },

    // 编辑公司名称
    editCompany(data) {
      this.company1.parentId1 = data.id;
      this.company1.name1 = data.name;
      this.dialogVisible3 = true;
    },
    doEditCompany() {
      if (this.company1.name1 != "") {
        companySystem
          .updateCompanyInfo(this.company1.parentId1, this.company1.name1)
          .then(() => {
            this.dialogVisible3 = false;
            this.getAllCompanyList();
          });
      } else {
        this.$message.error("公司名称不能为空！");
      }
      // companySystem.updateCompanyInfo(this.company.parentId).then((res)=>{

      // })
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  padding-right: 8px;
}
.add,
.del {
  font-size: 16px;
  padding: 2px 5px;
}
</style>